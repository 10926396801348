import request from '@/utils/request';
const appName = "huanxinxiang-taiyue";

// 支付宝签约刷单-无需Token
export const alipaySinglePayAPI = (params, data) => request({
  url: '/huanxinxiang-service/v1/alipay-no-sign-pay/',
  method: 'post',
  params,
  data,
  headers: {
    "App-Name": 'huanxinxiang-taiyue',
  },
});

// 福州泰岳主体视听甄选会员/优生活会员
// 支付宝购买会员-无需验证码-无需Token
export function alipayPayAPI(data) {
  return request({
    url: "/huanxinxiang-service/v1/alipay-pay/",
    method: "post",
    headers: {
      "App-Name": appName,
    },
    data,
  });
}
